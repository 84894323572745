import { CommissionSettings } from "../../rewards/models/commission-settings";
import { UserModel } from "../../users/models";

export class AccountModel {
  id: string;
  name?: string;
  apiKey?: string;
  defaultCurrency = "usd";
  defaultCommissionSettings?: CommissionSettings;
  invitationsFromPartners: boolean;
  payoutDelay?: number; // Number(30) == 30 days until rewards are marked as "ready to pay"
  owner?: UserModel;
  disabledAt?: Date;
  createdAt: Date;

  [key: string]: string | number | Date | unknown;

  isDisabled() {
    return Boolean(this.disabledAt);
  }

  get displayName() {
    return this.name ?? this.owner?.email;
  }

  static fromRequest(params: Partial<AccountModel>) {
    const model = new AccountModel();
    model.initProperties(params);

    if (params.defaultCommissionSettings) {
      model.defaultCommissionSettings = CommissionSettings.fromRequest(params.defaultCommissionSettings);
    }
    if (params.owner) {
      model.owner = UserModel.fromRequest(params.owner);
    }

    return model;
  }

  protected initProperties(params: Partial<AccountModel>) {
    Object.assign(this, params);

    if (params.disabledAt) {
      this.disabledAt = new Date(params.disabledAt);
    }
    if (params.createdAt) {
      this.createdAt = new Date(params.createdAt);
    }
  }
}

export default AccountModel;
